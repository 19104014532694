import React from "react";
import logo from "./logo_ds_pivot_slash.svg";
import "./App.css";
import { useSpring, animated } from "react-spring";
import * as easings from "d3-ease";

function App() {
  const fade = useSpring({
    config: {
      mass: 1,
      tension: 15,
      friction: 2,
      velocity: 4,
      easing: easings.easeExpOut
    },
    from: {
      opacity: 0,
      width: "20vmin"
    },
    to: {
      opacity: 1,
      width: "40vmin"
    }
  });

  return (
    <div className="App">
      <animated.img
        src={logo}
        className="App-logo"
        alt="logo"
        style={fade}
        onClick={event =>
          (window.location.href = "https://www.xing.com/profile/Daniel_Schlitt")
        }
      />
    </div>
  );
}

export default App;
